const INTER = [
  { space_src: "baidujs", space_type: "inters", space_id: "u6924417", space_js: "//tpfp.zadsdk.com/common/lgf/o/source/v/production/c/openjs/cph.js" }
];
const HOMENATIVEINTER = [
  { space_src: "baidujs", space_type: "inters", space_id: "u6924418", space_js: "//tpfp.zadsdk.com/production/mhgp/production/w/resource/dd_qj.js" }
];
const HOMEDIALOG = [
  { space_src: "baidujs", space_type: "native", space_id: "u6924418", space_js: "//tpfp.zadsdk.com/production/mhgp/production/w/resource/dd_qj.js" }
];
const TOPNATIVE = [{ space_src: "baidujs", space_type: "native", space_id: "u6924419", space_js: "//tpfp.zadsdk.com/site/ni/common/h_qxe/e_rq.js" }];
const CENTERNTIVE = [
  { space_src: "baidujs", space_type: "native", space_id: "u6924420", space_js: "//tpfp.zadsdk.com/source/f_az/openjs/i/production/pww/common/pg.js" }
];
const BANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6924421", space_js: "//tpfp.zadsdk.com/site/g_ba/common/jqx/xq_k.js" }];
const TOPICTOP = [{ space_src: "baidujs", space_type: "native", space_id: "u6921195", space_js: "//tpfp.zadsdk.com/site/ni/common/h_qxe/e_rq.js" }];
const TOPICCENTER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6924420", space_js: "//tpfp.zadsdk.com/source/f_az/openjs/i/production/pww/common/pg.js" }
];
const TOPICBANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6924421", space_js: "//tpfp.zadsdk.com/site/g_ba/common/jqx/xq_k.js" }];
const ARTICLEDETAILTOP = [{ space_src: "baidujs", space_type: "native", space_id: "u6924419", space_js: "//tpfp.zadsdk.com/site/ni/common/h_qxe/e_rq.js" }];
const ARTICLEDETAILCENTER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6924420", space_js: "//tpfp.zadsdk.com/source/f_az/openjs/i/production/pww/common/pg.js" }
];
const ARTICLEDETAILBANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6924421", space_js: "//tpfp.zadsdk.com/site/g_ba/common/jqx/xq_k.js" }];
const ARTICLEDETAILNATIVEINTER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6924418", space_js: "//tpfp.zadsdk.com/production/mhgp/production/w/resource/dd_qj.js" }
];
const IMGDETAILTOP = [{ space_src: "baidujs", space_type: "native", space_id: "u6924419", space_js: "//tpfp.zadsdk.com/site/ni/common/h_qxe/e_rq.js" }];
const IMGDETAILCENTER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6924420", space_js: "//tpfp.zadsdk.com/source/f_az/openjs/i/production/pww/common/pg.js" }
];
const IMGDETAILBANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6924421", space_js: "//tpfp.zadsdk.com/site/g_ba/common/jqx/xq_k.js" }];
const IMGDETAILNATIVEINTER = [
  { space_src: "baidujs", space_type: "native", space_id: "u6924418", space_js: "//tpfp.zadsdk.com/production/mhgp/production/w/resource/dd_qj.js" }
];
const PLAYDIALOG = [
  { space_src: "baidujs", space_type: "native", space_id: "u6924418", space_js: "//tpfp.zadsdk.com/production/mhgp/production/w/resource/dd_qj.js" }
];
const PLAYBANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6924421", space_js: "//tpfp.zadsdk.com/site/g_ba/common/jqx/xq_k.js" }];

/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: INTER, // 官方插屏
  Home_native_inter: HOMENATIVEINTER, //首页-自定义插屏
  Home_dialog: HOMEDIALOG, // 首页-对话框
  Home_top: TOPNATIVE, // 首页top信息流
  Home_center: CENTERNTIVE, // 首页-中部-信息流
  Home_banner: BANNER, // 首页Banner
  Topic_top: TOPICTOP, //主题列表信息流
  Topic_center: TOPICCENTER, // 主题列表信息流
  Topic_banner: TOPICBANNER, // 主题列表banner
  Article_detail_top: ARTICLEDETAILTOP, //文章详情top信息流
  Article_detail_center: ARTICLEDETAILCENTER, //文章详情信息流
  Article_detail_banner: ARTICLEDETAILBANNER, //文章详情banner
  Article_detail_native_inter: ARTICLEDETAILNATIVEINTER, //文章详情自定义插屏
  Img_detail_top: IMGDETAILTOP, //Img详情top信息流
  Img_detail_center: IMGDETAILCENTER, //Img详情信息流
  Img_detail_banner: IMGDETAILBANNER, //IMG详情banner
  Img_detail_native_inter: IMGDETAILNATIVEINTER, //IMG详情自定义插屏
  Play_dialog: PLAYDIALOG, // 测知识答案弹窗
  Play_banner: PLAYBANNER // 测知识答案弹窗
};
