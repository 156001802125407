import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getUrlParams } from "@/utils/common";
const params: AnyObject = getUrlParams(window.location.href);
const getRedirect = () => {
  if (process.env.VUE_APP_START == "index") return "/index";
  else if (process.env.VUE_APP_START == "guide") return "/guide";
  else if (params.qapp) return "/guide";
  else return "/index";
};
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/index",
    name: "Index",
    redirect: "/pageMain",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      spaceKeys: ["Home_top", "Home_inter", "Home_native_inter", "Home_banner"]
    },
    children: [
      {
        path: "/pageMain",
        name: "Home",
        component: () => import("@/views/home/PageMain.vue")
      },
      {
        path: "/pagePlay",
        name: "PagePlay",
        component: () => import("@/views/home/PagePlay.vue")
      },
      {
        path: "/pageRaphael",
        name: "PageRaphael",
        component: () => import("@/views/home/PageRaphael.vue")
      },
      {
        path: "/pageMummy",
        name: "PageMummy",
        component: () => import("@/views/home/PageMummy.vue")
      }
    ]
  },
  {
    path: "/topic-detail",
    name: "topicDetail",
    component: () => import("@/views/topic-detail/index.vue")
  },
  {
    // 文章详情
    path: "/article",
    name: "article",
    component: () => import("@/views/details/article.vue")
  },
  {
    // 博物馆文章详情
    path: "/img-detai",
    name: "imgDetai",
    component: () => import("@/views/details/img-detail.vue")
  },
  {
    // vr博物馆列表
    path: "/vr-museum",
    name: "vrMuseum",
    component: () => import("@/views/vr-museum/index.vue")
  },
  {
    // 冷知识
    path: "/answer-cold",
    name: "answerCold",
    component: () => import("@/views/answer-cold/index.vue")
  },
  {
    // 科普
    path: "/answer-science",
    name: "answerScience",
    component: () => import("@/views/answer-science/index.vue")
  },
  {
    path: "/guide",
    name: "Guidance",
    component: () => import("@/views/guideView.vue"),
    meta: {
      spaceKeys: ["Guide_native", "Guide_banner"]
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 0);
    });
  },
  routes
});

export default router;
