/*
 * @Date: 2023-07-13 15:20:06
 */
import type { Directive, DirectiveBinding } from "vue";
import { showToast } from "vant";
interface ElType extends HTMLElement {
  copyData: string | number;
  __handleClick__: any;
}
const copy: Directive = {
  mounted(el: ElType, binding: DirectiveBinding) {
    el.copyData = binding.value;
    el.addEventListener("click", handleClick);
  },
  updated(el: ElType, binding: DirectiveBinding) {
    el.copyData = binding.value;
  },
  beforeUnmount(el: ElType) {
    el.removeEventListener("click", el.__handleClick__);
  }
};

function handleClick(this: any) {
  const input = document.createElement("input");
  input.style.opacity = "0";
  input.value = this.copyData.toLocaleString();
  document.body.appendChild(input);
  input.select();
  document.execCommand("Copy");
  document.body.removeChild(input);
  // showNotify({ type: "success", message: "复制成功~" });
  showToast("复制成功~");
}

export default copy;
