import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import directives from "./directives/index";

import "animate.css";

import "./plugins/flexble";
import Vconsole from "vconsole";
import "./assets/styles/main.less";
import "./assets/styles/index.less";
import "vant/lib/index.css";
import { List, Image, Toast, Swipe, SwipeItem, Icon, Lazyload, Tab, Tabs, Overlay, BackTop, Loading, Button, ImagePreview } from "vant";
import { SPACE_MAP } from "@/utils/constan";
import { Haa } from "@haa/fwk-h5/lib/index";
import "@haa/fwk-h5/lib/styles/index.css";
import WapInter from "@haa/fwk-h5/components/wap-inter.vue";
import WapNative from "@haa/fwk-h5/components/wap-native.vue";
import WapBanner from "@haa/fwk-h5/components/wap-banner.vue";
import WapInterNative from "@haa/fwk-h5/components/wap-inter-native.vue";
import TopArea from "@/components/common/TopArea.vue";
const app = createApp(App);

app.use(List);
app.use(Image);
app.use(Toast);
app.use(Swipe);
app.use(SwipeItem);
app.use(Icon);
app.use(Lazyload);
app.use(Tab);
app.use(Tabs);
app.use(Overlay);
app.use(BackTop);
app.use(Loading);
app.use(Button);
app.use(ImagePreview);
app.component("WapInter", WapInter);
app.component("WapNative", WapNative);
app.component("WapBanner", WapBanner);
app.component("WapInterNative", WapInterNative);
app.component("TopArea", TopArea);
app.use(store);
app.use(directives);
app.use(router);
const cfg = {
  queryMap: {
    // query的key
    channel: "channel",
    subchannel: "subchannel",
    key: "key"
  },
  spaceMap: SPACE_MAP, // 广告位配置
  router: router, // router实例
  home: "Home", // 路由首页的name，对于首页有多个子页面的，可以传数组['Home','Analysis']
  app: app, // 应用实例
  deepStart: process.env.VUE_APP_START == "guide" ? 1 : 2
};
Haa.init(cfg); // sdk
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (IS_DEBUG || process.env.NODE_ENV == "development") {
  const vConsole = new Vconsole();
  app.use(<any>vConsole);
}
app.mount("#app");
